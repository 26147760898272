.k-card-header {
  display: none;
}

.k-dialog {
  padding: 0;
  position: fixed;
  box-sizing: border-box;
  border-radius: 20px !important;
}

.k-dialog-wrapper {
  z-index: 2 !important;
}

.k-grid-norecords td {
  font-family: 'Poppins';
  color: #25245f !important;
}

/*TabStrip*/
.k-tabstrip-top>.k-tabstrip-items-wrapper li.k-item {
  background-color: transparent;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper li.k-item span {
  color: var(--primary);
  font-weight: 400;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper li.k-item.k-state-active {
  border: none;
  border-bottom: 2px solid var(--blue);
}

.k-tabstrip-top>.k-tabstrip-items-wrapper li.k-item.k-state-active span {
  color: var(--blue);
  font-weight: bold;
}

.k-tabstrip-content,
.k-tabstrip>.k-content {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
  text-align: center;
}

.k-input-solid {
  border-color: rgba(211, 211, 223, 1);
}

.k-input-solid:hover {
  border-color: rgba(10, 120, 205, 1);
}

.k-input-solid:focus-within {
  box-shadow: none !important;
  border-color: #0A78CD !important;
}

.k-input-inner:focus {
  background-color: #fff !important;
  /* border: 1px solid #0A78CD !important; */
  caret-color: #0A78CD;
  box-shadow: none;
  margin: 1px;
  border-radius: 5px;
}

.k-dateinput:focus {
  border: none;
}

.k-input-value-text {
  color: #25245f !important;
}

.k-tabstrip-content.k-state-active,
.k-tabstrip>.k-content.k-state-active {
  border: none;
  padding: 1.5rem 0;
}

/*Dialog*/
.k-widget.k-window.k-dialog .k-window-actions.k-dialog-actions button {
  display: none;
}

.k-widget.k-window.k-dialog {
  overflow: hidden;
}

.k-widget.k-window.k-dialog .k-window-titlebar {
  background-color: #f9f9f9;
  color: var(--darkBlue);
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  font-weight: bold;
}

.k-widget.k-window.k-dialog .k-window-titlebar {
  min-height: 4.5rem;
  padding: 1rem 1.6rem;
}

.k-widget.k-window.k-dialog .k-window-titlebar .k-window-title {
  padding: 0;
}

.k-widget.k-window.k-dialog .k-window-titlebar button {
  padding: 0 !important;
  border-radius: 0.2rem !important;
}

.k-widget.k-window.k-dialog .k-window-content.k-dialog-content {
  padding: 1rem 3.5rem;
}

.k-i-calendar::before {
  content: url('assets/vault icons/calendarIcon.svg') !important;
}

