/*NOTE: display*/
.inline-flex {
  display: inline-flex;
}

/*NOTE: Box Size*/

.max-w-90 {
  max-width: 80%;
}

.max-w-80 {
  max-width: 80%;
}

.max-w-70 {
  max-width: 70%;
}

.max-w-60 {
  max-width: 60%;
}

.max-w-50 {
  max-width: 50%;
}

.max-w-40 {
  max-width: 40%;
}

.max-w-30 {
  max-width: 30%;
}

.max-w-20 {
  max-width: 20%;
}

.max-w-10 {
  max-width: 10%;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.no-border {
  border: none;
}

.no-border-radius {
  border-radius: 0;
}

.margin-auto {
  margin: 0 auto;
}
