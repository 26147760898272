/*NOTE: Text Size*/
.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-light {
  font-weight: lighter;
}

.text-xs {
  font-size: 1rem !important;
}

.text-sm {
  font-size: 1.2rem !important;
}

.text-normal {
  font-size: 1.4rem !important;
}

.text-lg {
  font-size: 1.6rem !important;
}

.text-xl {
  font-size: 1.8rem !important;
}

.text-xxl {
  font-size: 2rem !important;
}

.text-xxxl {
  font-size: 2.2rem !important;
}

.text-underline {
  text-decoration: underline;
}

/*NOTE: Typograph*/
.section-title {
  letter-spacing: 0.05rem;
  line-height: 3rem;
}

label,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'poppins';
}

.page-title {
  font-size: 3.6rem;
}

.text-title {
  font-size: 3rem;
}

.text-md-title {
  color: var(--primary);
  font-weight: bold;
  font-size: 2.3rem;
}

.primary-header {
  font-size: 2rem;
}

.secondary-header,
.section-title {
  font-size: 1.6rem;
}

.font14px{
  font-size: 14px !important;
}