/* button {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  border-radius: 2rem !important;
  line-height: 3rem !important;
  padding: 0 1.5rem !important;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* button,
a {
  font-family: 'Poppins';
} */

/* .button {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  border-radius: 2rem !important;
  line-height: 3rem !important;
  padding: 0 1.5rem !important;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* button:hover,
button:active {
  transform: scale(1.01) !important;
  transition: 0.1s all !important;
} */

/* .button:hover,
.button:active {
  transform: scale(1.01) !important;
  transition: 0.1s all !important;
} */

/* button:focus {
  box-shadow: 0 0 0 0.25rem rgba(37, 36, 95, 0.8) !important;
} */

.button-primary {
  background-color: var(--primary) !important;
  border-color: var(--white) !important;
  color: var(--white) !important;
}

.button-primary:hover,
.button-primary:active {
  background-color: var(--white) !important;
  border-color: var(--primary) !important;
  color: var(--primary) !important;
}

.button-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(37, 36, 95, 0.8) !important;
}

.button-secondary {
  background-color: var(--secondary) !important;
  border-color: var(--white) !important;
  color: var(--white) !important;
}

.button-grey,
.button-disable {
  background-color: var(--grey) !important;
  border-color: var(--white) !important;
  color: var(--white) !important;
}
.button-grey:focus,
.button-disable:focus {
  box-shadow: 0 0 0 0.25rem rgba(169, 169, 169, 0.4) !important;
}

.button-grey:hover,
.button-grey:active,
.button-disable:hover,
.button-disable:active {
  background-color: var(--white) !important;
  border-color: var(--grey) !important;
  color: var(--grey) !important;
}

.button-secondary:hover,
.button-secondary:active {
  background-color: var(--white) !important;
  border-color: var(--secondary) !important;
  color: var(--secondary) !important;
}

.butoton-secondary:focus {
  box-shadow: 1px 1px 1px 0.25rem rgba(10, 120, 205, 0.8) !important;
}

.button-danger {
  background-color: var(--danger) !important;
  border-color: var(--white) !important;
  color: var(--white) !important;
}

.button-danger:hover,
.button-danger:active,
.butoton-danger:focus {
  background-color: var(--white) !important;
  border-color: var(--danger) !important;
  color: var(--danger) !important;
}

.butoton-danger:focus {
  box-shadow: 0 0 0 0.25rem var(--danger) !important;
}

.button-pink {
  background-color: var(--pink) !important;
  border-color: var(--white) !important;
  color: var(--white) !important;
}

.button-pink:hover,
.button-pink:active {
  background-color: var(--white) !important;
  border-color: var(--pink) !important;
  color: var(--pink) !important;
}

.butoton-pink:focus {
  box-shadow: 0 0 0 0.25rem var(--pink) !important;
}

.button-purple {
  background-color: var(--purple) !important;
  border-color: var(--white) !important;
  color: var(--white) !important;
}

.button-purple:hover,
.button-purple:active {
  background-color: var(--white) !important;
  border-color: var(--purple) !important;
  color: var(--purple) !important;
}

.button-purple:focus {
  box-shadow: 0 0 0 0.25rem rgba(187, 7, 220, 0.8) !important;
}

.btnPrimary {
  background: linear-gradient(270deg, #00cba9 0%, #469cf8 100%) !important;
  border-radius: 50px !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  /* min-width: 250px; */
  /* height: 40px !important; */
  text-transform: none !important;
  font-weight: 900 !important;
}

.btnPrimary:disabled {
  background: rgba(211, 211, 223, 1) !important;
  border-radius: 50px !important;
  border: none !important;
  color: rgba(102, 102, 143, 1) !important;
  font-size: 14px !important;
  /* min-width: 250px; */
  /* height: 40px !important; */
  text-transform: none !important;
  font-weight: 900 !important;
  pointer-events: none;
}

.btnFilledDelete {
  background: #d14343 !important;
  border-radius: 50px !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  text-transform: none !important;
  font-weight: 900 !important;
}

.btnPrimary:hover {
  background: linear-gradient(270deg, #01ac8f 0%, #397bc2 100%, #0000 50%) !important;
  border-radius: 50px !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  text-transform: none !important;
  font-weight: 900 !important;
}

.secondary {
  background: '#D3D3DF' !important;
  border-radius: 50px !important;
  border: none !important;
  color: #66668f !important;
  font-size: 14px !important;
}

.btnPrimary2 {
  background: linear-gradient(270deg, #00cba9 0%, #469cf8 100%) !important;
  border-radius: 0px !important;
  border: none !important;
  color: #fff !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  min-width: 250px;
  /* height: 40px !important; */
  text-transform: none !important;
  font-weight: 900 !important;
}

.btnSecondary {
  background: #f7fafb !important;
  border-radius: 0px !important;
  border: none !important;
  color: #0a78cd !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  width: 150px !important;
  height: 40px !important;
  text-transform: none !important;
}

.btnClose {
  background: var(--grey) !important;
  border-radius: 50px !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  /* height: 40px !important; */
  text-transform: none !important;
  font-weight: 900 !important;
}

.btnPrimaryOutline {
  border-radius: 50px;
  color: var(--secondary);
  border: 2px solid var(--secondary);
  background-color: white;
  cursor: pointer;
  min-width: 250px;
  height: 40px !important;
}

.btnDanger {
  border-radius: 50px;
  color: var(--white);
  background: var(--danger);
  border: 2px solid var(--danger);
  cursor: pointer;
  min-width: 250px;
  height: 40px !important;
  text-transform: none !important;
  font-weight: 900 !important;
}

.btnPrioritized {
  background: linear-gradient(white, white) padding-box, linear-gradient(270deg, #00cba9, #469cf8) border-box;
  border: 2px solid transparent !important;
  color: #0a78cd !important;
  border-radius: 50em !important;
  font-size: 14px !important;
  text-transform: none !important;
  font-weight: 600 !important;
}

.btnDelete {
  border: 2px solid #d14343 !important;
  color: #d14343 !important;
  border-radius: 50em !important;
  font-size: 14px !important;
  /* width: 150px !important; */
  text-transform: none !important;
  height: 40px !important;
  /* height: 40px !important; */
  background: #ffff !important;
}

.btnContained {
  background: linear-gradient(270deg, #00cba9 0%, #469cf8 100%) !important;
  border-radius: 50px !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  text-transform: none !important;
  font-weight: 900 !important;
  height: 40px;
}

.btnContained:disabled {
  background: linear-gradient(270deg, #00cba95e 0%, #469cf86c 100%) !important;
  border-radius: 50px !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  text-transform: none !important;
  font-weight: 900 !important;
}
.roundedBtn{
  border-radius: 30px !important;
}
