/* Disable reveal icon globally for Microsoft edge (DASH-2186) */
input::-ms-reveal, input::-ms-clear {
    display: none;
}
/* text color for the uneditable textbox (DASH-2127)*/
.Mui-disabled {
    -webkit-text-fill-color: #9292AF !important;
}

.MuiSelect-select:focus {
    background-color: transparent;
}