@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Corbel';
  src: url('assets/fonts/Corbel/Corbel-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Corbel';
  src: url('assets/fonts/Corbel/Corbel\ Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Corbel';
  src: url('assets/fonts/Corbel/Corbel\ Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Corbel';
  src: url('assets/fonts/Corbel/Corbel\ Bold\ Italic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('assets/fonts/Century\ Gothic/Century\ Gothic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('assets/fonts/Century\ Gothic/Century\ Gothic\ Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
