@import 'kendoCustom.css';
@import 'utils.css';
@import 'buttons.css';
@import 'typograph.css';
@import 'box.css';
@import 'fonts.css';

/*NOTE: 1rem = 10px*/
html {
  font-size: 10px;
  overflow-y: overlay;
}

:root {
  --primary: rgb(37, 36, 95);
  --secondary: rgb(10, 120, 205);
  --white: rgb(255, 255, 255);
  --darkBlue: rgb(37, 36, 95);
  --blue: rgb(10, 120, 205);
  --teal: rgb(0, 226, 188);
  --pink: rgb(231, 42, 166);
  --purple: rgb(187, 7, 220);
  --grey: rgb(128, 128, 128);
  --darkgrey: rgb(169, 169, 169);
  --dimgrey: rgb(105, 105, 105);
  --green: rgb(33, 187, 63);
  --darkTurpuoise: rgb(0, 101, 125);
  --danger: rgb(220, 53, 69);
  --darkPurple: rgb(77, 4, 174);
  --lightGrey: rgb(232, 232, 232);
}

body {
  background-color: #f2f8fc;
  margin: 0;
  font-family: 'Poppins', 'Corbel', 'Century Gothic';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input {
  font-family: 'Poppins';
}

.form-input-wrapper input:hover {
  border-color: rgb(10, 120, 205);
}

.form-input-wrapper input:focus {
  outline: none;
}
