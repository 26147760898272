/*
NOTE: colors
*/

.color-darkBlue,
.color-primary {
  color: var(--darkBlue);
}

.color-outline-darkBlue,
.color-outline-primary {
  outline: var(--darkBlue);
  border: 1px solid var(--darkBlue);
}

.color-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(37, 36, 95, 0.5) !important;
}

.background-darkBlue,
.background-primary {
  background-color: var(--darkBlue);
}

.color-blue,
.color-secondary {
  color: var(--blue);
}

.color-outline-blue,
.color-outline-secondary {
  outline: var(--blue);
  border: 1px solid var(--blue);
}

.color-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 120, 205, 0.5) !important;
}

.background-blue,
.background-secondary {
  background-color: var(--blue);
}

.color-pink {
  color: var(--pink);
}

.color-outline-pink {
  outline: var(--pink);
  border: 1px solid var(--pink);
}

.color-outline-pink:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 42, 166, 0.5) !important;
}

.background-pink {
  background-color: var(--pink);
}

.color-purple {
  color: var(--purple);
}

.color-outline-purple {
  outline: var(--purple);
  border: 1px solid var(--purple);
}

.color-outline-purple:focus {
  box-shadow: 0 0 0 0.2rem rgba(187, 7, 220, 0.5) !important;
}

.background-purple {
  background-color: var(--purple);
}

.color-teal {
  color: var(--teal);
}

.color-outline-teal {
  outline: var(--teal);
  border: 1px solid var(--teal);
}

.color-outline-purple:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 226, 188, 0.5) !important;
}

.background-teal {
  background-color: var(--teal);
}

.color-grey {
  color: #dedede;
}

.color-outline-grey {
  outline: #dedede;
  border: 1px solid rgb(158, 158, 158);
}

.color-outline-grey:focus {
  box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.5) !important;
}

.background-grey {
  background-color: #dedede;
}

.color-green {
  color: var(--green);
}

.color-outline-green {
  outline: var(--green);
  border: 1px solid var(--green);
}

.color-outline-green:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 187, 63, 0.5) !important;
}

.background-green {
  background-color: var(--green);
}

.color-dark-turquoise {
  color: var(--darkTurpuoise);
}

.color-outline-dark-turquoise {
  outline: var(--darkTurpuoise);
  border: 1px solid var(--darkTurpuoise);
}

.color-outline-dark-turquoise:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 101, 125, 0.5) !important;
}

.background-dark-turquoise {
  background-color: var(--darkTurpuoise);
}

.color-dark-purple {
  color: var(--darkPurple);
}

.color-outline-dark-purple {
  outline: var(--darkPurple);
  border: 1px solid var(--darkPurple);
}

.color-outline-dark-purple:focus {
  box-shadow: 0 0 0 0.2rem rgba(187, 7, 220, 0.5) !important;
}

.background-dark-purple {
  background-color: var(--darkPurple);
}

.color-success {
  color: #69c95a;
}

.color-outline-success {
  outline: #69c95a;
  border: 1px solid rgb(105, 201, 90);
}

.color-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 201, 90, 0.5) !important;
}

.background-success {
  background-color: #69c95a;
}

.color-danger {
  color: #dc3545;
}

.color-outline-danger {
  outline: #dc3545;
  border: 1px solid rgb(220, 53, 69);
}

.color-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5) !important;
}

.background-danger {
  background-color: #dc3545;
}

.color-white {
  color: white;
}

.color-outline-white {
  outline: white;
  border: 1px solid white(220, 53, 69);
}

.color-outline-white:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important;
}

.background-white {
  background-color: white;
}

/* .customAuthInput .MuiInputLabel-formControl{
  position: static;
    margin-bottom: 6px;
    font-size: 18px;
    font-weight: 600;
    color: #25245F;
    font-family: 'Poppins';
    font-weight: bold;
    line-height: 19px;
    letter-spacing: -0.47px;
} */

 /* .customAuthInput2 .css-y5f5yw-MuiTypography-root{
  margin-bottom: 21px;
} */

.verticalLine {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  height: 500px;
  position: absolute;
  left: 45%;
  
}
.bootstrapLabel label{
  position: relative;
  top: -10px;
  left: -14px;
  font-weight: bold;
  font-size: 14px;
  color: #25245F;
}
.bootstrapLabel .css-1q60rmi-MuiAutocomplete-endAdornment {
  /* top: calc(50% - 11px) !important; */
  color: #66668f !important;
}
.CustomAutoComplete ul .MuiAutocomplete-option{
  border-bottom: solid 1px #ccc;
}
.CustomAutoComplete ul .MuiAutocomplete-option:last-child{
  border-bottom: none !important;
}


.link-hover-message {
  display: none;
  z-index: 100;
  position: absolute;
  text-align: left;
  background: #0A78CD;
  color: white;
  width: 32%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 98%;
  padding: 15px;
  border-radius: 13px;
  box-shadow: 0px 16px 17px -6px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 0px 16px 17px -6px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 16px 17px -6px rgba(0, 0, 0, 0.18);
}

.link-hover-message::before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  right: auto;
  top: auto;
  bottom: -23px;
  border: 12px solid;
  transform: translateX(-50%);
  border-color: transparent;
  border-top: 12px solid #0A78CD;
}

.markaaz-payments-link-container :hover + div.link-hover-message {
  display: block;
}

.disclaimer-text {
  font-size: x-small;
}

.markaaz-payments-link-container {
  width: fit-content;
  margin: auto;
  cursor: pointer;
}

.markaaz-payments-link-container :hover + div.link-hover-message {
  display: block;
}


.hiddenArrow-input input::-webkit-outer-spin-button,
.hiddenArrow-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
